#game-night {
    position: relative;
    padding-bottom: 12rem;
    background: white;
    z-index: 3;

    @include breakpoint(tablet) {
        padding-bottom: 8rem;
    }

    @include breakpoint(mobile) {
        margin-top: -2rem;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: skewY(-4deg);
        transform-origin: top left;
        background: linear-gradient(180deg, #008bda 0%, #03579d 100%);
        z-index: 1;
        pointer-events: none;
    }

    .container {
        z-index: 2;
        overflow: hidden;

        h2 {
            color: white;
            margin-top: 4rem;

            @include breakpoint(tablet) {
                text-align: center;
            }
        }

        .content {
            margin-top: 2rem;
            position: relative;

            & > img {
                max-width: 1440px;
                width: 100%;
            }

            .fade-in-element:not(.get-app) {
                position: absolute;
                top: 2.7rem;
                right: 0;

                @include breakpoint(laptop) {
                    position: relative;
                    top: unset;
                }
                p {
                    color: white;
                    max-width: 325px;

                    @include breakpoint(desktop) {
                        max-width: 290px;
                    }

                    @include breakpoint(laptop) {
                        max-width: unset;
                    }
                }
            }

            .get-app {
                position: absolute;
                bottom: 1.68rem;

                @include breakpoint(tablet) {
                    display: none;
                }

                img {
                    @include breakpoint(laptop) {
                        width: 80%;
                    }

                    & + img {
                        margin-top: 1.68rem;

                        @include breakpoint(laptop) {
                            margin-top: 0.7rem;
                        }
                    }
                }
            }
        }
    }
}

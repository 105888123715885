$primaryColor: #008bda;
$secondaryColor: #09203c;

$primaryFont: 'Roboto', 'Open Sans';
$secondaryFont: 'Albra', 'Open Sans';

@mixin breakpoint($point) {
    @if $point == desktop {
        @media (max-width: 1440px) {
            @content;
        }
    } @else if $point == smalldesktop {
        @media (max-width: 1260px) {
            @content;
        }
    } @else if $point == laptop {
        @media (max-width: 1120px) {
            @content;
        }
    } @else if $point == tablet {
        @media (max-width: 900px) {
            @content;
        }
    } @else if $point == phablet {
        @media (max-width: 768px) {
            @content;
        }
    } @else if $point == mobile {
        @media (max-width: 500px) {
            @content;
        }
    } @else {
        @media ($point) {
            @content;
        }
    }
}

@font-face {
    font-display: swap;
    font-family: 'Albra';
    font-weight: 800;
    src: local('FontsFree-Net-AlbraBlack'),
        url(../assets/fonts/FontsFree-Net-AlbraBlack.ttf) format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-weight: 400;
    src: local('Roboto-Regular'),
        url(../assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-weight: 500;
    src: local('Roboto-Medium'), url(../assets/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-weight: 700;
    src: local('Roboto-Bold'), url(../assets/fonts/Roboto-Bold.ttf) format('truetype');
}

body {
    background-color: #edf4fb;
    font-family: $primaryFont;
    overflow-x: hidden;
}

section,
header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container {
    width: 100%;
    max-width: 1440px;
    padding: 0 3.75rem;

    @include breakpoint(phablet) {
        padding: 0 1.68rem;
    }
}

p {
    font-size: 18px;
    color: rgba(72, 72, 72, 1);

    @include breakpoint(desktop) {
        font-size: 16px;
    }

    @include breakpoint(laptop) {
        font-size: 15px;
    }

    @include breakpoint(tablet) {
        font-size: 14px;
    }
}

h2 {
    font-size: 64px;
    font-family: $secondaryFont;

    @include breakpoint(desktop) {
        font-size: 54px;
    }

    @include breakpoint(laptop) {
        font-size: 26px;
    }

    @include breakpoint(tablet) {
        font-size: 36px;
    }
}

.litepaper-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    max-width: 330px;
    width: 100%;
    border-radius: 8px;
    background-color: $primaryColor;
    color: white;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
}

.kyc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    max-width: 330px;
    width: 100%;
    border-radius: 8px;
    background-color: $primaryColor;
    color: white;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
}

.fade-in-element {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
        visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;

    &.up {
        transform: translateY(20vh);
    }

    &.down {
        transform: translateY(-20vh);
    }

    &.left {
        transform: translateX(20vw);
    }

    &.right {
        transform: translateX(-20vw);
    }

    &.in-view {
        opacity: 1;
        transform: none;
        visibility: visible;
    }
}

#blockchain {
    background-color: white;
    position: relative;
    z-index: 2;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50vw;
        transform: skewY(-4deg);
        transform-origin: top left;
        background-color: white;
        z-index: -1;
        pointer-events: none;
    }

    .container {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        align-items: center;
        margin: 5rem 0 15rem 0;

        @include breakpoint(tablet) {
            grid-template-columns: minmax(0, 1fr);
            margin-bottom: 7rem;
        }

        img {
            margin-left: -8rem;
            max-width: 750px;

            @include breakpoint('max-width: 1650px') {
                max-width: 100%;
                margin-left: -2rem;
            }

            @include breakpoint(tablet) {
                margin-top: 3rem;
            }
        }

        .content {
            @include breakpoint(tablet) {
                order: -1;
            }
            h2 {
                color: $primaryColor;
                margin-bottom: 2rem;

                @include breakpoint(desktop) {
                    margin-bottom: 1.68rem;
                }

                @include breakpoint(tablet) {
                    text-align: center;
                }
            }

            .fade-in-element:not(:first-of-type) {
                & + .fade-in-element {
                    margin-top: 1.68rem;

                    @include breakpoint(desktop) {
                        margin-top: 1rem;
                    }
                }
            }

            p {
                max-width: 540px;

                @include breakpoint(tablet) {
                    max-width: unset;
                }
            }
        }
    }
}

 header {
    // background-color: #152f44;
    top: 0;
    position: sticky;
    padding: 1.4rem 0;
    z-index: 9999;
    background-color: #152f44;

    // &::before {
    //     content: '';
    //     position: absolute;
    //     right: 0;
    //     bottom: 57%;
    //     left: 0;
    //     width: 100%;
    //     height: 50vw;
    //     transform: skewY(-4deg);
    //     transform-origin: top left;
    //     background-color: #e6eef7;
    //     pointer-events: none;

    //     @include breakpoint(laptop) {
    //         bottom: 67%;
    //     }

    //     @include breakpoint(phablet) {
    //         height: 50vh;
    //     }
    // }

    .container {
        z-index: 1;

        .links-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .litepaper-button {
                height: 40px;
                font-size: 18px;
                line-height: 21px;
                // max-width: 250px;
                max-width: 220px;


                @include breakpoint(tablet) {
                    // max-width: 200px;
                    max-width: 195px;
                }

                @include breakpoint(phablet) {
                    // max-width: 160px;
                    max-width: 140px;
                    // font-size: 16px;
                    font-size: 14px;
                }

                @include breakpoint(mobile) {
                    height: 32px;
                    // max-width: 125px;
                    max-width: 100px;
                    // font-size: 13px;
                    font-size: 9px;

                }

                @include breakpoint('max-width:400px') {
                    height: 28px;
                    // max-width: 100px;
                    max-width: 90px;
                    // font-size: 10px;
                    font-size: 9px;

                }
            }

            
            .kyc-button {
                height: 40px;
                font-size: 18px;
                line-height: 21px;
                max-width: 250px;

                @include breakpoint(tablet) {
                    max-width: 195px;
                }

                @include breakpoint(phablet) {
                    max-width: 140px;
                    font-size: 14px;
                }

                @include breakpoint(mobile) {
                    height: 32px;
                    max-width: 90px;
                    font-size: 9px;
                }

                @include breakpoint('max-width:400px') {
                    height: 28px;
                    max-width: 85px;
                    font-size: 9px;
                }
            }

            .social-medias {
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    & + a {
                        margin-left: 1.68rem;

                        @include breakpoint(tablet) {
                            // margin-left: 1rem;
                            margin-left: 0.9rem;
                        }

                        @include breakpoint(phablet) {
                            margin-left: 0.5rem;
                        }        

                        @include breakpoint(mobile) {
                            // margin-left: 0.5rem;
                            margin-left: 0.4rem;

                        }

                        @include breakpoint('max-width:400px') {
                            // margin-left: 0.3rem;
                            margin-left: 0.2rem;
                        }
                    }

                    svg {
                        @include breakpoint(tablet) {
                            width: 40px;
                            height: auto;
                        }

                        @include breakpoint(mobile) {
                            width: 32px;
                        }

                        @include breakpoint('max-width:400px') {
                            width: 28px;
                            height: auto;
                        }
                    }
                }
            }
        }

        // .incubation {
        //     margin-top: 10rem;
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     justify-content: center;

        //     span {
        //         font-size: 24px;
        //         line-height: 28.13px;
        //         font-weight: 500;
        //         color: white;
        //         margin-top: 1rem;
        //         text-align: center;
        //     }

        //     .projects {
        //         width: 100%;
        //         display: grid;
        //         grid-template-columns: repeat(8, max-content);
        //         justify-content: space-between;
        //         margin: 5rem 0 10rem 0;

        //         @include breakpoint(laptop) {
        //             grid-template-columns: repeat(4, max-content);
        //             justify-items: center;
        //             gap: 1.68rem;

        //             img {
        //                 &:nth-of-type(4) {
        //                     order: 5;
        //                 }
        //                 &:nth-of-type(5) {
        //                     order: 6;
        //                 }
        //                 &:nth-of-type(6) {
        //                     order: 7;
        //                 }
        //                 &:nth-of-type(7) {
        //                     order: 4;
        //                 }
        //                 &:nth-of-type(8) {
        //                     order: 8;
        //                 }
        //             }
        //         }

        //         @include breakpoint(phablet) {
        //             gap: unset;
        //             display: flex;
        //             flex-wrap: wrap;
        //             align-items: flex-start;
        //             max-width: 400px;
        //             margin-bottom: 6rem;
        //         }

        //         img {
        //             @include breakpoint(phablet) {
        //                 max-width: 18%;
        //                 margin-bottom: 1.68rem;

        //                 &:nth-of-type(3) {
        //                     max-width: 30%;
        //                     margin-top: -0.5rem;
        //                 }

        //                 &:nth-of-type(4) {
        //                     max-width: 17%;
        //                 }

        //                 &:nth-of-type(5) {
        //                     max-width: 17%;
        //                     margin-left: 0.5rem;
        //                 }

        //                 &:nth-of-type(6) {
        //                     margin: 0.5rem 0.5rem 0 0.5rem;
        //                     max-width: 15%;
        //                 }

        //                 &:nth-of-type(8) {
        //                     max-width: 40%;
        //                     margin-top: -0.5rem;
        //                 }
        //             }

        //             @include breakpoint(mobile) {
        //                 margin-bottom: 1rem;
        //             }
        //         }
        //     }
        // }
    }
}

#intellectual-property {
    background: white;
    position: relative;
    z-index: 2;
    overflow-x: hidden;

    .container {
        h2 {
            color: $primaryColor;
            margin: 5rem 0 2rem 0;

            @include breakpoint(tablet) {
                text-align: center;
            }

            @include breakpoint(phablet) {
                margin-bottom: 1rem;
            }
        }

        img {
            max-width: 1440px;
            width: 100%;
        }

        p {
            margin-top: 2rem;
            font-size: 24px;
            line-height: 28.13px;

            @include breakpoint(tablet) {
                font-size: 18px;
            }

            @include breakpoint(phablet) {
                font-size: 14px;
                line-height: 16.5px;
                margin-top: 1rem;
            }
        }
    }
}

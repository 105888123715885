#token {
    margin-top: -1rem;
    position: relative;
    background: #e6eef7;
    padding-bottom: 15rem;
    z-index: 3;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: skewY(4deg);
        transform-origin: top right;
        background: linear-gradient(180deg, #008ad9 5.99%, #035297 105.42%);
        z-index: 1;
        pointer-events: none;
    }

    .container {
        z-index: 2;

        .token-utility {
            position: relative;

            @include breakpoint(tablet) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            h2 {
                position: absolute;
                top: 3rem;
                color: white;

                @include breakpoint(tablet) {
                    position: relative;
                    top: unset;
                    text-align: center;
                    margin: 5rem 0;
                }
            }
        }

        .token-metrics {
            h2 {
                color: white;

                @include breakpoint(tablet) {
                    text-align: center;
                    margin-top: 8rem;
                }
            }

            .content {
                margin-top: 5rem;
                display: grid;
                grid-template-columns: max-content max-content;
                gap: 2.5rem;

                @include breakpoint(desktop) {
                    grid-template-columns: minmax(0, 1fr);
                    margin-top: 3rem;
                }

                @include breakpoint(laptop) {
                    gap: unset;
                }

                @include breakpoint(tablet) {
                    margin-top: 2rem;
                }

                // .token-basic-info {
                //     color: white;
                //     grid-column: 1/2;

                //     @include breakpoint(laptop) {
                //         grid-column: unset;
                //         justify-self: center;
                //     }

                //     p {
                //         color: inherit;
                //         line-height: 32px;

                //         @include breakpoint(tablet) {
                //             text-align: center;
                //             font-size: 21px;
                //         }

                //         span {
                //             font-weight: 500;
                //             font-size: 24px;
                //             line-height: inherit;

                //             @include breakpoint(desktop) {
                //                 font-size: 22px;
                //             }

                //             @include breakpoint(laptop) {
                //                 font -size: 21px;
                //             }
                //         }
                //     }
                // }

                .total-supply {
                    display: grid;
                    grid-template-columns: repeat(2, max-content);
                    grid-template-rows: max-content;
                    align-self: end;
                    row-gap: 1rem;

                    @include breakpoint(desktop) {
                        order: -1;
                        justify-content: center;
                        margin-bottom: 2.5rem;
                    }

                    @include breakpoint(laptop) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    picture {
                        grid-column: 1/3;

                        img {
                            width: 345px;
                        }

                        @include breakpoint(laptop) {
                            grid-column: unset;
                        }
                    }

                    .text,
                    .value {
                        font-size: 24px;
                        line-height: 16px;
                        font-weight: 700;
                        color: white;

                        @include breakpoint(laptop) {
                            display: none;
                        }
                    }

                    .value {
                        text-align: right;
                    }

                    .token-details {
                        color: white;
                        display: none;
                        text-align: center;

                        @include breakpoint(laptop) {
                            display: block;
                        }

                        p {
                            color: inherit;
                            line-height: 32px;

                            @include breakpoint(tablet) {
                                text-align: center;
                                font-size: 21px;
                            }

                            span {
                                font-weight: 500;
                                font-size: 24px;
                                line-height: inherit;

                                @include breakpoint(desktop) {
                                    font-size: 22px;
                                }

                                @include breakpoint(laptop) {
                                    font-size: 21px;
                                }
                            }
                        }
                    }
                }

                .table {
                    display: grid;
                    grid-template-columns: repeat(9, max-content);
                    column-gap: 1.68rem;
                    color: white;
                    font-size: 12px;
                    line-height: 16px;
                    align-items: center;

                    @include breakpoint(desktop) {
                        grid-template-columns: max-content repeat(8, minmax(0, 1fr));
                    }

                    @include breakpoint(laptop) {
                        grid-template-columns: repeat(6, minmax(0, 1fr));
                    }

                    @include breakpoint(tablet) {
                        column-gap: 0.7rem;
                    }

                    @include breakpoint(phablet) {
                        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) max-content;
                    }

                    @include breakpoint(mobile) {
                        font-size: 10px;
                        column-gap: 0.5rem;
                        grid-template-columns: minmax(0, 1fr) max-content max-content;
                    }

                    & > div {
                        display: contents;

                        @include breakpoint(phablet) {
                            &.header,
                            &.row,
                            &.total {
                                div {
                                    &:nth-of-type(4),
                                    &:nth-of-type(5),
                                    &:nth-of-type(6) {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .dashed {
                            grid-column: 1/7;
                            border-top: 1px dashed white;
                            height: 1px;

                            @include breakpoint(phablet) {
                                grid-column: 1/4;
                            }
                        }

                        .border {
                            grid-column: 2/10;
                            border-top: 1px solid white;

                            @include breakpoint(laptop) {
                                grid-column: 1/7;
                            }

                            @include breakpoint(phablet) {
                                grid-column: 1/4;
                            }
                        }

                        &.header > div:not(.border) {
                            margin-bottom: 2rem;

                            &.default {
                                @include breakpoint(laptop) {
                                    display: none;
                                }
                            }

                            &:not(.default) {
                                display: none;

                                @include breakpoint(laptop) {
                                    display: block;
                                }
                            }

                            @include breakpoint(laptop) {
                                &:nth-last-child(2),
                                &:nth-last-child(4),
                                &:nth-last-child(6),
                                &:nth-last-child(8) {
                                    display: none;
                                }
                            }

                            @include breakpoint(phablet) {
                                &:nth-child(n + 6) {
                                    display: none;
                                }
                            }

                            @include breakpoint(phablet) {
                                margin-bottom: 1.68rem;
                            }
                        }

                        &.total > div {
                            margin-top: 3rem;
                            font-weight: 700;

                            @include breakpoint(phablet) {
                                margin-top: 1.68rem;
                            }
                        }

                        &.row {
                            &:last-of-type {
                                @include breakpoint(laptop) {
                                    font-weight: 700;

                                    & > div {
                                        margin-top: 1.68rem;

                                        &:first-of-type {
                                            .legend-color {
                                                display: none;
                                            }
                                        }
                                    }

                                    .border {
                                        display: none;
                                    }
                                }

                                @include breakpoint(phablet) {
                                    & > div {
                                        margin-top: unset;
                                    }
                                }
                            }

                            @include breakpoint(phablet) {
                                &:nth-last-child(2) .border {
                                    border-top: 1px solid white;
                                }
                            }

                            & > div {
                                display: flex;
                                align-items: center;

                                @include breakpoint(laptop) {
                                    &:nth-last-child(2),
                                    &:nth-last-child(3),
                                    &:nth-last-child(4) {
                                        display: none;
                                    }
                                }

                                &:not(.border) {
                                    height: 33px;
                                }

                                &:first-of-type {
                                    @include breakpoint(laptop) {
                                        height: 55px;
                                    }

                                    .legend-color {
                                        margin-right: 1rem;
                                        display: none;

                                        @include breakpoint(laptop) {
                                            display: block;
                                        }

                                        @include breakpoint(mobile) {
                                            margin-right: 0.5rem;
                                        }
                                    }

                                    span {
                                        white-space: nowrap;

                                        &.default {
                                            @include breakpoint(laptop) {
                                                display: none;
                                            }
                                        }

                                        &:not(.default) {
                                            display: none;
                                            @include breakpoint(laptop) {
                                                display: block;
                                            }
                                        }
                                    }
                                }

                                &:not(:first-of-type) span {
                                    &.default {
                                        display: block;

                                        @include breakpoint(laptop) {
                                            display: none;
                                        }
                                    }

                                    &:not(.default) {
                                        display: none;
                                        @include breakpoint(laptop) {
                                            display: block;
                                        }
                                    }
                                }
                            }

                            & > .border {
                                border-top: 1px dashed white;
                            }
                        }

                        .legend-color {
                            width: 26px;
                            height: 26px;
                            border-radius: 4px;

                            @include breakpoint(mobile) {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        &:nth-of-type(2) .legend-color {
                            background-color: rgba(237, 244, 251, 0.7);
                        }

                        &:nth-of-type(3) .legend-color {
                            background-color: white;
                        }

                        &:nth-of-type(4) .legend-color {
                            background-color: #b5c4ce;
                        }

                        &:nth-of-type(5) .legend-color {
                            background-color: #8199a9;
                        }

                        &:nth-of-type(6) .legend-color {
                            background-color: #445e71;
                        }

                        &:nth-of-type(7) .legend-color {
                            background-color: #1b2f3d;
                        }
                        &:nth-of-type(8) .legend-color {
                            background-color: #0d1b25;
                        }
                    }
                }
            }
        }
    }
}

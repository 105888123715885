#highlights {
    background-color: white;
    position: relative;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    .container {
        z-index: 2;
        margin-bottom: 5rem;

        h2 {
            margin-top: 5rem;
            color: $primaryColor;

            @include breakpoint(tablet) {
                text-align: center;
            }
        }

        .content {
            display: grid;
            grid-template-columns: repeat(6, minmax(0, 1fr));
            gap: 1.68rem;
            margin-top: 4rem;

            @include breakpoint(laptop) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            @include breakpoint(phablet) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            .highlight {
                .icon {
                    position: relative;
                    display: flex;
                    justify-content: center;

                    img {
                        width: 100%;
                        max-width: 200px;
                        min-width: 120px;
                    }
                }

                span {
                    font-size: 20px;
                    line-height: 23.4px;
                    font-weight: 500;
                    text-align: center;
                    display: block;
                    white-space: nowrap;

                    @include breakpoint(desktop) {
                        font-size: 16px;
                    }

                    @include breakpoint(laptop) {
                        font-size: 18px;
                    }

                    @include breakpoint(phablet) {
                        font-size: 16px;
                    }

                    @include breakpoint(mobile) {
                        margin-top: -1.5rem;
                        font-size: 14px;
                    }
                }
            }
        }
        .movies {
            max-width: 1440px;
            width: 100%;
        }

        .skewed-background {
            position: absolute;
            bottom: calc(100vw * 255 / 368 - 4%);
            right: 0;
            left: 0;
            width: 100%;
            height: 100vw;
            transform: skewY(2deg);
            transform-origin: top right;
            background: linear-gradient(180deg, #008bda 0%, #035aa0 458.51%);
            z-index: -1;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                left: 0;
                bottom: 250px;
                width: 100%;
                height: 100%;
                transform: skewY(-4deg);
                transform-origin: top left;
                background-color: white;
                z-index: -1;

                @include breakpoint(laptop) {
                    bottom: 180px;
                }

                @include breakpoint(tablet) {
                    transform: skewY(-6deg);
                    bottom: 120px;
                }

                @include breakpoint(mobile) {
                    bottom: 90px;
                }
            }
        }
    }

    .family {
        width: 100vw;
    }
}

#advisors {
    background-color: #e6eef7;
    z-index: 2;
    position: relative;
    padding-bottom: 8rem;

    .container {
        h2 {
            color: $primaryColor;

            @include breakpoint(tablet) {
                text-align: center;
                margin-bottom: 2rem;
            }
        }

        .content {
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            column-gap: 1.68rem;
            row-gap: 3.75rem;

            @include breakpoint(laptop) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            .advisor {
                @include breakpoint(laptop) {
                    text-align: center;
                }

                .name {
                    font-size: 24px;
                    line-height: 28.13px;
                    font-weight: 500;
                    color: $secondaryColor;

                    @include breakpoint(desktop) {
                        font-size: 18px;
                    }

                    @include breakpoint(laptop) {
                        padding-right: 4rem;
                        white-space: nowrap;
                    }

                    @include breakpoint(mobile) {
                        font-size: 16px;
                        padding-right: unset;
                    }
                }

                .company {
                    display: flex;
                    align-items: center;
                    margin-top: 0.5rem;

                    @include breakpoint(laptop) {
                        justify-content: center;
                        padding-right: 4rem;
                    }

                    @include breakpoint(mobile) {
                        padding-right: unset;
                    }

                    img {
                        margin-right: 1rem;

                        @include breakpoint(desktop) {
                            margin-right: 0.7rem;
                        }

                        @include breakpoint(laptop) {
                            margin-right: 0.5rem;
                        }
                    }

                    span {
                        font-size: 18px;
                        line-height: 21px;
                        font-weight: 500;
                        color: $secondaryColor;

                        @include breakpoint(desktop) {
                            font-size: 16px;
                        }

                        @include breakpoint(mobile) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }

                &:nth-of-type(1) {
                    .company {
                        img {
                            max-width: 48px;

                            @include breakpoint(desktop) {
                                max-width: 40px;
                            }
                        }
                    }
                }

                &:nth-of-type(2) {
                    .company img {
                        max-width: 83px;

                        @include breakpoint(desktop) {
                            max-width: 60px;
                        }

                        @include breakpoint(laptop) {
                            max-width: 50px;
                        }
                    }
                }

                &:nth-of-type(3) {
                    .company {
                        img {
                            max-width: 90px;

                            @include breakpoint(desktop) {
                                max-width: 80px;
                            }
                        }
                    }
                }

                &:nth-of-type(4) {
                    .company {
                        margin-top: unset;
                    }
                }

                &:nth-of-type(5) {
                    .company {
                        img {
                            @include breakpoint(desktop) {
                                max-width: 80px;
                            }
                        }
                    }
                }

                &:nth-of-type(6) {
                    .company {
                        img {
                            max-width: 96px;

                            @include breakpoint(desktop) {
                                max-width: 80px;
                            }
                        }
                    }
                }

                &:nth-of-type(7) {
                    .company img {
                        max-width: 141px;

                        @include breakpoint(desktop) {
                            max-width: 120px;
                        }
                    }
                }

                &:nth-of-type(8) {
                    .company img {
                        max-width: 104px;

                        @include breakpoint(desktop) {
                            max-width: 90px;
                        }
                    }
                }

                &:nth-of-type(9) {
                    .company img {
                        @include breakpoint(desktop) {
                            max-width: 110px;
                        }

                        @include breakpoint(mobile) {
                            max-width: 90px;
                        }
                    }
                }

                &:last-of-type {
                    .company {
                        img {
                            max-width: 110px;

                            @include breakpoint(desktop) {
                                max-width: 100px;
                            }

                            @include breakpoint(mobile) {
                                max-width: 90px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#roadmap {
    background-color: #e6eef7;

    .container {
        padding-bottom: 25rem;

        @include breakpoint(desktop) {
            padding-bottom: 25rem;
        }

        @include breakpoint(laptop) {
            padding-bottom: 10rem;
        }

        @include breakpoint(tablet) {
            padding-bottom: 10rem;
        }

        @include breakpoint(mobile) {
            padding-bottom: 10rem;
        }

        h2 {
            color: $primaryColor;
            margin-bottom: 3.75rem;

            @include breakpoint(desktop) {
                // margin-bottom: 8rem;
                margin-bottom: -4rem;
            }

            @include breakpoint(laptop) {
                margin-bottom: 3.75rem;
            }

            @include breakpoint(tablet) {
                text-align: center;
                margin-top: 3rem;
            }
        }

        .content {
            margin: 0 auto;
            position: relative;

            .roadmap-full-image {
                width: 100%;
                margin-top: 10%;

                @include breakpoint(desktop) {
                    margin-top: 20%;
                }

                @include breakpoint(laptop) {
                    display: none;
                }
            }

            .roadmap {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                @include breakpoint(laptop) {
                    position: relative;
                }

                .road {
                    position: absolute;

                    @include breakpoint(laptop) {
                        position: relative;
                        display: grid;
                        grid-template-columns: max-content minmax(0, 1fr);
                        column-gap: 1.68rem;

                        & + .road {
                            margin-top: -8px;
                        }
                    }

                    @include breakpoint(mobile) {
                        column-gap: unset;
                    }

                    &:last-of-type {
                        .road-line .line {
                            display: none;
                        }

                        .detail {
                            padding-bottom: 0;
                        }
                    }

                    .road-line {
                        display: none;
                        flex-direction: column;
                        align-items: center;

                        @include breakpoint(laptop) {
                            display: grid;
                            grid-template-rows: max-content minmax(0, 1fr);
                            justify-items: center;
                            align-items: start;

                            .line {
                                height: 100%;
                            }
                        }

                        .line {
                            margin-top: -5px;
                        }
                    }

                    .detail {
                        @include breakpoint(laptop) {
                            padding-bottom: 3rem;
                        }

                        .timeline {
                            position: relative;
                            font-weight: 700;
                            font-size: 36px;
                            line-height: 42px;
                            color: $primaryColor;
                            padding-left: 32px;
                            display: flex;
                            align-items: center;

                            @include breakpoint(desktop) {
                                font-size: 32px;
                                padding-left: 35px;
                            }

                            @include breakpoint(smalldesktop) {
                                font-size: 25px;
                            }

                            @include breakpoint(laptop) {
                                font-size: 36px;
                                line-height: 42px;
                                padding-left: 48px;
                            }

                            @include breakpoint(mobile) {
                                padding-left: 40px;
                                font-size: 30px;
                            }

                            &::before {
                                content: '';
                                position: absolute;
                                border-radius: 50%;
                                border: 8px solid $primaryColor;
                                width: 9px;
                                height: 9px;
                                left: 0;
                            }
                        }

                        .title {
                            margin-top: 0.5rem;
                            font-size: 16px;
                            line-height: 21px;
                            font-weight: bold;
                            max-width: 192px;

                            @include breakpoint(desktop) {
                                font-size: 16px;
                                max-width: 192px;
                            }

                            @include breakpoint(laptop) {
                                max-width: 230px;
                            }

                            @include breakpoint(tablet) {
                                font-size: 16px;
                                margin-top: 0.5rem;
                                max-width: 230px;
                            }

                            @include breakpoint(mobile) {
                                font-size: 14px;
                                line-height: 16px;
                                max-width: 200px;
                            }
                        }

                        ul {
                            padding-left: 18px;

                            @include breakpoint(laptop) {
                                margin-top: 0.5rem;
                            }
                            li {
                                font-size: 16px;
                                line-height: 21px;

                                @include breakpoint(desktop) {
                                    // font-size: 16px;
                                    font-size: 15px;
                                }

                                @include breakpoint(smalldesktop) {
                                    font-size: 14px;
                                }

                                @include breakpoint(laptop) {
                                    font-size: 15px;
                                }

                                @include breakpoint(mobile) {
                                    font-size: 14px;
                                    line-height: 16px;
                                }
                            }
                        }
                    }

                    &:first-of-type {
                        top: 65%;
                        left: 1%;
                        // max-width: 270px;
                        max-width: 200px;

                        .title span {
                            display: block;

                            & + span {
                                margin-top: 0.5rem;
                            }
                        }

                        li {
                            list-style-type: "✔ ";
                        }

                        @include breakpoint(desktop) {
                            // max-width: 220px;
                            max-width: 200px;
                            left: 1%;
                        }

                        @include breakpoint(laptop) {
                            top: unset;
                            left: unset;
                            max-width: unset;
                        }
                    }

                    &:nth-of-type(2) {
                        top: -5%;
                        max-width: 200px;
                        left: 18%;

                        li {
                            list-style-type: "✔ ";
                        }

                        @include breakpoint(desktop) {
                            // top: -30%;
                            top: -5%;
                            // max-width: 290px;
                            max-width: 200px;
                            // left: 24%;
                            left: 18%;
                        }

                        @include breakpoint(laptop) {
                            left: unset;
                            top: unset;
                            max-width: unset;
                        }
                    }

                    &:nth-of-type(3) {
                        top: 65%;
                        left: 35%;
                        // max-width: 285px;
                        max-width: 200px;

                        li {
                            list-style-type: circle;
                        }

                        ul:last-child li:nth-child(1) {
                            list-style-type: "✔ ";
                        }

                        @include breakpoint(laptop) {
                            top: unset;
                            left: unset;
                            max-width: unset;
                        }
                    }

                    &:nth-of-type(4) {
                        top: -43%;
                        max-width: 200px;
                        left: 52%;

                        li {
                            list-style-type: circle;
                        }

                        @include breakpoint(desktop) {
                            top: -43%;
                            max-width: 200px;
                            left: 52%;
                        }

                        @include breakpoint(smalldesktop) {
                            top: -60%;
                        }

                        @include breakpoint(laptop) {
                            left: unset;
                            top: unset;
                            max-width: unset;
                        }
                    }

                    &:nth-of-type(5) {
                        top: 65%;
                        left: 69%;
                        max-width: 200px;

                        li {
                            list-style-type: circle;
                        }

                        @include breakpoint(laptop) {
                            top: unset;
                            left: unset;
                            max-width: unset;
                        }
                    }

                    &:last-of-type {
                        top: -5%;
                        left: 86%;
                        // max-width: 260px;
                        max-width: 200px;

                        li {
                            list-style-type: circle;
                        }

                        @include breakpoint(desktop) {
                            // top: -30%;
                            top: -5%; 
                        }

                        @include breakpoint(laptop) {
                            top: unset;
                            left: unset;
                            max-width: unset;
                        }
                    }
                }
            }
        }
    }
}

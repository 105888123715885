#leadership-team {
    background-color: #e6eef7;

    .container {
        padding-bottom: 10rem;

        h2 {
            color: $primaryColor;

            @include breakpoint(tablet) {
                text-align: center;
                margin-bottom: 5rem;
            }
        }

        .leaders {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            margin-top: 1.68rem;
            row-gap: 1.68rem;

            @include breakpoint(desktop) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                justify-items: center;
                text-align: center;
            }

            @include breakpoint(phablet) {
                gap: 1.68rem;
            }

            @include breakpoint(mobile) {
                grid-template-columns: minmax(0, 1fr);
            }

            .leader {
                .profile {
                    @include breakpoint(desktop) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .name {
                    font-size: 24px;
                    line-height: 28.13px;
                    color: $primaryColor;
                    font-weight: 500;
                    margin-top: 1.68rem;
                }

                .position {
                    font-size: 18px;
                    line-height: 21px;
                    color: $primaryColor;
                }

                .companies {
                    margin: 1rem 0;
                    display: flex;
                    align-items: center;
                    min-height: 64px;

                    @include breakpoint(desktop) {
                        justify-content: center;
                    }

                    img + img {
                        margin-left: 1rem;
                    }
                }

                p {
                    line-height: 21px;
                    max-width: 295px;
                }
            }
        }
    }
}

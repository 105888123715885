#hero {
    overflow: hidden;

    .container {
        position: relative;

        @include breakpoint(tablet) {
            text-align: center;
        }

        .logo-container {
            margin-top: 3rem;
            position: relative;
            z-index: -2;
            max-width: 1440px;
        }

        .description {
            font-size: 30px;
            line-height: 35.16px;
            font-weight: 500;

            @include breakpoint(desktop) {
                font-size: 26px;
            }

            @include breakpoint(laptop) {
                font-size: 18px;
                line-height: 28px;
            }

            p {
                color: #09213c;
                font-size: 30px;
                margin: 2.5rem 0;

                @include breakpoint(desktop) {
                    font-size: 26px;
                    margin: 1.5rem 0;
                }

                @include breakpoint(laptop) {
                    font-size: 18px;
                    margin: 1rem 0;
                }
            }

            span {
                color: $primaryColor;
            }
        }

        .opportunity-and-solution {
            position: relative;
            max-width: 440px;
            margin-top: 4rem;

            @include breakpoint(desktop) {
                margin-top: 2rem;
            }

            @include breakpoint(laptop) {
                margin-top: 1rem;
            }

            @include breakpoint(tablet) {
                max-width: unset;
                margin-top: 3rem;
            }

            @include breakpoint(phablet) {
                margin-top: 2rem;
            }

            .opportunity,
            .solution {
                margin-left: 3rem;

                @include breakpoint(laptop) {
                    margin-left: 1.68rem;
                }

                @include breakpoint(tablet) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-left: unset;
                }

                h3 {
                    font-family: $secondaryFont;
                    color: #09213c;
                    font-size: 42px;
                    line-height: 57.59px;

                    @include breakpoint(desktop) {
                        font-size: 36px;
                        line-height: 48px;
                    }

                    @include breakpoint(laptop) {
                        font-size: 26px;
                        line-height: 30px;
                    }

                    @include breakpoint(tablet) {
                        font-size: 32px;
                        line-height: 43.88px;
                    }
                }

                p {
                    margin-top: 1.68rem;
                    line-height: 21px;

                    @include breakpoint(desktop) {
                        margin-top: 1rem;
                    }
                }
            }

            .opportunity p {
                max-width: 388px;

                @include breakpoint(tablet) {
                    min-width: 300px;
                    max-width: unset;
                    text-align: left;
                }
            }

            .solution {
                margin-top: 6.5rem;

                @include breakpoint(desktop) {
                    margin-top: 2rem;
                }

                p {
                    max-width: 441px;

                    @include breakpoint(desktop) {
                        max-width: 355px;
                    }

                    @include breakpoint(laptop) {
                        max-width: 330px;
                    }

                    @include breakpoint(tablet) {
                        min-width: 300px;
                        max-width: unset;
                        text-align: left;
                    }
                }
            }

            svg {
                position: absolute;
                transform: translate(-45%, 1rem);

                @include breakpoint(desktop) {
                    height: calc(319px * 0.65);
                }

                @include breakpoint(laptop) {
                    transform: translate(-50%, 1rem);
                    height: calc(319px * 0.55);
                }

                @include breakpoint(tablet) {
                    display: none;
                }
            }

            .litepaper-button {
                margin-left: 3rem;
                margin-top: 1.68rem;

                @include breakpoint(laptop) {
                    margin-left: 1.68rem;
                }

                @include breakpoint(tablet) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .about-steve {
            display: grid;
            grid-template-columns: max-content minmax(0, 1fr);
            grid-template-rows: max-content minmax(0, 1fr);
            column-gap: 1rem;
            margin: 6rem 0;

            @include breakpoint(desktop) {
                margin-top: 3rem;
                margin-bottom: 4rem;
            }

            @include breakpoint(laptop) {
                column-gap: 0.5rem;
                margin-top: 2rem;
                margin-bottom: 3rem;
            }

            @include breakpoint(tablet) {
                padding-bottom: 650px;
                grid-template-columns: max-content max-content;
                justify-items: center;
                margin: 2rem auto 0 auto;
                width: max-content;
                padding-bottom: unset;
            }

            .trophy-container {
                grid-row: 1/3;
                img.trophy {
                    @include breakpoint(desktop) {
                        width: 150px;
                        height: auto;
                    }

                    @include breakpoint(laptop) {
                        width: 120px;
                    }

                    @include breakpoint(phablet) {
                        width: 100px;
                    }
                }
            }

            .about,
            ul {
                grid-column: 2/3;
            }

            .about {
                margin-top: 1rem;
                width: 160px;
                height: 160px;
                border-radius: 50%;
                background-color: $primaryColor;
                color: white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;

                @include breakpoint(desktop) {
                    width: 140px;
                    height: 140px;
                    margin-top: 0.5rem;
                }

                @include breakpoint(laptop) {
                    width: 110px;
                    height: 110px;
                    text-align: center;
                }

                @include breakpoint(tablet) {
                    position: absolute;

                    width: 150px;
                    height: 150px;
                    left: 50%;
                    transform: translate(-150%, 15rem);
                }

                @include breakpoint(phablet) {
                    width: 130px;
                    height: 130px;
                }

                @include breakpoint(mobile) {
                    transform: translate(-100%, 15rem);
                }

                .line {
                    position: absolute;
                    transform: translate(0, 11.5px);
                    left: 100%;

                    @include breakpoint(tablet) {
                        display: none;
                    }

                    svg {
                        @include breakpoint(desktop) {
                            width: 20vw;
                            height: auto;
                        }

                        @include breakpoint('max-width: 1000px') {
                            width: 15vw;
                        }
                    }

                    &::after {
                        content: '';
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: $primaryColor;
                        position: absolute;
                        transform: translate(0, 10px);
                    }
                }

                span {
                    &.title {
                        font-size: 18px;
                        line-height: 21px;

                        @include breakpoint(desktop) {
                            font-size: 15px;
                        }

                        @include breakpoint(laptop) {
                            font-size: 14px;
                            line-height: 16px;
                            width: 50px;
                        }

                        @include breakpoint(tablet) {
                            font-size: 16px;
                            width: unset;
                        }

                        @include breakpoint(phablet) {
                            font-size: 14px;
                        }
                    }

                    &:not(.title) {
                        font-size: 18.5px;
                        line-height: 23.44px;
                        font-weight: 500;

                        @include breakpoint(desktop) {
                            font-size: 15px;
                        }

                        @include breakpoint(laptop) {
                            font-size: 14px;
                            line-height: 16px;
                        }

                        @include breakpoint(tablet) {
                            font-size: 16px;
                        }

                        @include breakpoint(phablet) {
                            font-size: 14px;
                        }
                    }
                }
            }

            & > .fade-in-element {
                align-self: end;
            }

            ul {
                margin: 0 0 5px 0;
                font-size: 18px;
                line-height: 21px;
                color: #252429;
                list-style: none;
                padding-left: 15px;

                @include breakpoint(laptop) {
                    font-size: 15px;
                    line-height: 16px;
                }

                @include breakpoint(tablet) {
                    font-size: 14px;
                    text-align: left;
                }

                @include breakpoint(mobile) {
                    margin-left: -1.5rem;
                }

                li {
                    padding-left: 0.5rem;

                    &::marker {
                        color: $primaryColor;
                        content: '\2022';
                        font-size: 25px;
                        font-weight: bold;
                    }
                }
            }
        }

        .steve {
            position: absolute;
            bottom: 0;
            right: -5rem;
            z-index: -1;

            @include breakpoint(desktop) {
                width: 1070px;
                height: auto;
                left: 50%;
                transform: translate(-40%, 0);
            }

            @include breakpoint(laptop) {
                width: 800px;
                max-width: unset;
                left: 52%;
            }

            @include breakpoint(tablet) {
                transform: translate(-60%, 0);
                position: relative;
                margin-top: -8rem;
            }

            @include breakpoint(phablet) {
                width: 700px;
                margin-top: -5rem;
            }

            @include breakpoint(mobile) {
                right: -64%;
                left: unset;
            }
        }
    }
}
